import { Button, Card, Input, Tag, Radio } from "antd";
import { useContext, useRef, useState } from "react";

import { AppContext } from "../../App";
import { PageContext, usePageActionHandler } from '../../components/Page';
import CommonPage from "../../components/CommonPage"
import { post } from "../../utils/fetch";

const AccountPage = () => {
  const { appState } = useContext(AppContext);
  const { setError, isActionInProgress } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const openAiKeyInputRef = useRef(null);
  let openAiApiKey = localStorage.getItem('openAiApiKey');
  let currentAiModel = localStorage.getItem('aiModel');
  const [saveApiKeysSuccess, setSaveApiKeysSuccess] = useState(false);
  const [aiModel, setAiModel] = useState(currentAiModel || "gpt-3.5-turbo");

  const handleSaveApiKeys = async () => {
    const api = `/update-api-keys`;
    addAction(api)
    await post(api, {
      openAiKey: openAiKeyInputRef.current.input.value,
      aiModel
    })
      .then((res) => {
        if (openAiKeyInputRef.current.input.value) {
          localStorage.setItem('openAiApiKey', openAiKeyInputRef.current.input.value);
          localStorage.setItem('aiModel', aiModel);
        } else {
          localStorage.removeItem('openAiApiKey');
          localStorage.removeItem('aiModel');
        }
        setSaveApiKeysSuccess(true);
      })
      .catch((err) => {
        setError(err)
      })
      removeAction(api)
  }

  return (
    <CommonPage title="Account">
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', gap: 20 }}>
            <h3>Profile</h3>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>Id:</span>
            <span>{appState?.user?.id}</span>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>Registered email:</span>
            <span>{appState?.user?.email}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', gap: 20, alignItems: 'baseline' }}>
            <h3>Credits</h3>
            {
              appState?.user?.plan_id === 'trial' &&
              (
                <Tag color="orange">Trial</Tag>
              )
            }
            {
              appState?.user?.plan_id === 'v1_sub' &&
              (
                <Tag color="blue">Subscription active</Tag>
              )
            }
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>AI credits:</span>
            <span>{Math.round((appState?.user?.ai_credit || 0) / 100)}</span>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>Document credits:</span>
            <span>{appState?.user?.doc_credit}</span>
          </div>
        </div>
      </Card>
      {
        appState?.features?.['use-own-openai-key']
        && (
          <Card>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div style={{ display: 'flex', gap: 20, alignItems: 'baseline' }}>
                <h3>API key</h3>
              </div>
              <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                {
                  openAiApiKey && <div>Remove the API keys to start using platform credits.</div>
                }
                {
                  openAiApiKey && (
                    <Radio.Group onChange={(val) => { setAiModel(val.target.value) }} value={aiModel}>
                      <Radio value="gpt-3.5-turbo">GPT 3.5 Turbo</Radio>
                      <Radio value="gpt-4">GPT 4</Radio>
                      <Radio value="gpt-4-turbo-preview">GPT 4 Turbo (Recommended)</Radio>
                    </Radio.Group>
                  )
                }
                {
                  appState?.features?.['use-own-openai-key'] && (
                    <Input
                      defaultValue={openAiApiKey}
                      ref={openAiKeyInputRef}
                      onClick={() => { setSaveApiKeysSuccess(false) }}
                      style={{ maxWidth: 600 }}
                      placeholder="OpenAI"
                      allowClear
                    />
                  )
                }
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <Button
                    disabled={isActionInProgress('/update-api-keys')}
                    loading={isActionInProgress('/update-api-keys')}
                    style={{ alignSelf: 'flex-start' }}
                    onClick={handleSaveApiKeys}
                  >
                    Save
                  </Button>
                  {
                    (saveApiKeysSuccess) &&
                    <span style={{ color: 'green' }}>Success</span>
                  }
                </div>
              </div>
            </div>
          </Card>
        )
      }

    </CommonPage>
  )
}

export default AccountPage;
